/**
@see {@link https://bitbucket.org/billsdotcom_bills/sunbeam/src/main/src/styles/tokens/breakpoints/breakpoints.json | Sunbeam Breakpoints}
*/

const BREAKPOINTS = {
  xxs: 'xxs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg', // For launch, desktop designs begin at the `lg` breakpoint
  xl: 'xl',
  xxl: 'xxl',
}

const maxBreakpoints = {
  [BREAKPOINTS.xxs]: 0,
  [BREAKPOINTS.xs]: 320,
  [BREAKPOINTS.sm]: 480,
  [BREAKPOINTS.md]: 768,
  [BREAKPOINTS.lg]: 1024,
  [BREAKPOINTS.xl]: 1280,
  [BREAKPOINTS.xxl]: 1440,
}

export { BREAKPOINTS, maxBreakpoints }
